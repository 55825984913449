import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { getTokens } from '../helper/helper';
import { FetchAuthTokens } from '../hooks/FetchAuthTokens';
import { useNavigate } from "react-router-dom"

const CognitoRedirect = () => {
    const [cookies, setCookie] = useCookies(['accessToken', 'idToken']);

    const navigate = useNavigate()

    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");


    useEffect(() => {
      FetchAuthTokens(code)
        .then((data) => {
          setCookie("accessToken", data.access_token, { path: "/" })
          setCookie("idToken", data.id_token, { path: "/" })
          setCookie("refreshToken", data.refresh_token, { path: "/" })
          navigate("/home")
        })
        .catch((error) => {
          console.error(error);
        });
    }, []);

    return (
        <div>
            {/* You can display a loading message or spinner here */}
            Loading...
        </div>
    );
};

export default CognitoRedirect;