import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { useNavigate } from "react-router-dom"

import { startTimer, endTimer } from "../helper/utils"

import { useSelector, useDispatch } from "react-redux"

import { useFetchQuestion, moveNextQuestion, movePreviousQuestion, selectQuestion } from "../hooks/FetchQuestions"
import { pushAnswer, updateResult } from "../hooks/SetResult"
import {
  HomeIcon,
} from "@heroicons/react/24/outline"

import Answer from "./global/Answers"
import QuestionSideBar from "./global/QuestionSideBar"

const TestView = (props) => {

  const state = useSelector(state => state)

  const [{isLoading, apiData, serverError}] = useFetchQuestion()

  const questions = useSelector(state => state.questions.queue[state.questions.trace])
  const examName = useSelector(state => state.init.examName)
  const { queue, trace } = useSelector(state => state.questions)
  const result = useSelector(state => state.result.result)

  const dispatch = useDispatch()
  
  const [check, setCheck] = useState(undefined)

  const onSelect = (check) => {
    setCheck(check)
    dispatch(updateResult({ trace, check }))
  }
  
  useEffect(() => {
    dispatch(updateResult({ trace, check }))
  },[check])

  useEffect(() => {
    let startTime = startTimer()
    return () => {
      let duration = endTimer(startTime)
      props.setDuration(duration)
    }
  },[])

  const navigate = useNavigate()

  const submitQuestion = () => {
    if(trace < queue.length) {
      dispatch(moveNextQuestion())
      if(result.length <= trace){
        dispatch(pushAnswer(check))
      }
    }
    setCheck(undefined)
  }

  const previousQuestion = () => {
    dispatch(movePreviousQuestion())
  }

  const jumpToQuestion = (id) => {
    dispatch(selectQuestion(id))
  }

  if(result.length && result.length >= queue.length) {
    navigate("/result")
  }

  return (
    <div class="flex flex-row">
      <div class="min-h-[85vh] relative column-1 w-2/12 bg-blue-600 rounded-md">
        <div class="flex flex-col items-center py-4 bg-blue-600 rounded-md">
          <HomeIcon className="h-6 w-6 text-white" aria-hidden="true" />
          <div class="hidden md:block my-4 text-md text-white mx-2 px-2 text-center">
            {examName}
          </div>
          <div class="mt-4 md:mt-1 flex-grow border-t w-11/12 border-white"></div>
        </div>
        {queue.map((question, i) => (
          <QuestionSideBar key={i} index={i} jumpToQuestion={jumpToQuestion} />
        ))}
      </div>
      <div class="flex flex-col md:flex-row w-10/12">
        <div class="column-2 w-full md:w-full">
          <div class="mx-4 my-2">
            <h3 class="font-medium leading-tight text-2xl mt-0 mb-2 text-black">
              Question {trace + 1}
            </h3>
          </div>
          <div class="px-4 py-2 mx-4 my-2 bg-white drop-shadow">
            {isLoading ? (
              <div>Loading...</div>
            ) : (
              <ReactMarkdown
                className="prose max-w-none"
                children={questions?.questionText}
              ></ReactMarkdown>
            )}
          </div>
          <div class="mx-4 my-2">
            <div className="mx-auto max-w-2xl lg:max-w-7xl">
              <div className="my-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-2 xl:gap-x-8">
                {questions?.options.map((option, i) => (
                  <Answer
                    question={option}
                    key={i}
                    id={i}
                    name={option}
                    onSelect={onSelect}
                    result={result}
                    trace={trace}
                    checked={check}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="bg-white px-4 py-3 text-right sm:px-6">
            <button
              type="submit"
              className={`inline-flex justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 ${
                check != null ? "" : "cursor-not-allowed bg-blue-400"
              }`}
              disabled={check != null ? false : true}
              onClick={() => {
                submitQuestion();
              }}
            >
              {trace + 1 < queue.length ? "Submit" : "Finish Test"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestView;
