import React from "react"
import { useDispatch, useSelector } from 'react-redux'
import { useCookies } from 'react-cookie'

import { setActiveInsight } from "../../redux/user_reducer"
import { updateInsights } from "../../hooks/GenerateInsight"

let InsightCard = (props) => {

    const dispatch = useDispatch()
    const [cookies] = useCookies(['accessToken'])


  if (!props.insights || props.insights.length === 0) {
    return <div>There are no insights currently.</div>;
  }
  return (
    <>
      {props.insights.map((insight, index) => (
        <div
          key={index}
          className="flex flex-col items-center sm:flex-row items-start text-left max-w-full p-6 my-4 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
        >
          <div className="mb-4 sm:mb-0 sm:mr-6">
            <p className="mb-3 font-normal text-gray-500 dark:text-gray-400 py-4 px-4">
              {insight.insight}
            </p>
            <a
              href={insight.reference}
              target="_blank"
              className="inline-flex font-medium items-center text-blue-600 hover:underline px-4"
            >
              Reference
              <svg
                className="w-3 h-3 ms-2.5 rtl:rotate-[270deg] mx-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 18 18"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778"
                />
              </svg>
            </a>
          </div>
          {insight.active ? (
            <button
            className="ml-auto inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              onClick={() => {
                dispatch(setActiveInsight(insight));
                dispatch(updateInsights(props.userName, insight, cookies.accessToken))
              }}
            >Complete
              <svg
                className="rtl:rotate-180 w-3.5 h-3.5 ms-2 mx-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </button>
          ) : (
            <button
              className="ml-auto inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-500 rounded-lg hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300"
            >Completed
            </button>
          )}
        </div>
      ))}
    </>
  );
};

export default InsightCard;
