import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

// import data, { answers, explanations } from "../config/demo/data"

import * as Action from "../redux/question_reducer"
import * as ResultAction from "../redux/result_reducer"
import { getServerData } from "../helper/helper"

export const fetchTopics = async (examType, questionType) => {
    try {
        const topics = await getServerData(`${process.env.REACT_APP_SERVER_HOSTNAME}/api/topics?examType=${examType}&questionType=${questionType}`, (data) => data)
        return topics
    } catch(error){
        console.log(error)
    }
}

export const useFetchQuestion = () => {
    const dispatch = useDispatch()
    const state = useSelector(state => state)
    const topics = state.init.examTopics.map((topic, i) => {
        return topic.value
    })
    const [getData, setGetData] = useState({ isLoading: false, apiData: [], serverError: null})

    useEffect(() => {
        setGetData(prev => ({...prev, isLoading: true}));

        (async () => {
            try {
                const questions = await getServerData(`${process.env.REACT_APP_SERVER_HOSTNAME}/api/questions?length=${state.init.examLength}&type=${state.init.certificationType}&topics=${topics}&difficulty=${state.init.difficulty}&questionType=${state.init.questionType}`, (data) => data)

                if(questions.length > 0){
                    setGetData(prev => ({...prev, isLoading: false}))
                    setGetData(prev => ({...prev, apiData:  questions}))

                    const questionIds = questions.map(question => question.questionId)

                    dispatch(Action.startExamAction(questions))
                    dispatch(ResultAction.saveQuestionIdsAction(questionIds))
                } else {
                    throw new Error("No Questions Available")
                }
            } catch(error){
                setGetData(prev => ({...prev, isLoading: false}))
                setGetData(prev => ({...prev, serverError: error}))
            }
        })()
    }, [dispatch])

    return [getData, setGetData]
}

export const moveNextQuestion = () => async (dispatch) => {
    try {
        dispatch(Action.moveNextAction())
    } catch(error){
        console.log(error)
    }
}

export const movePreviousQuestion = () => async (dispatch) => {
    try {
        dispatch(Action.movePreviousAction())
    } catch(error){
        console.log(error)
    }
}

export const selectQuestion = (id) => async (dispatch) => {
    try {
        dispatch(Action.selectQuestionAction(id))
    } catch(error){
        console.log(error)
    }
}