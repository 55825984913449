
// Call this function when the user lands on the page
export const startTimer = () => {
    let startTime = Date.now();
    return startTime
}

// Call this function when the user leaves the page
export const endTimer = (startTime) => {
    let endTime = Date.now()
    let timeSpent = endTime - startTime
    return timeSpent
}

// Call this function when you need to retrieve the week number in a year.
export const getWeekNumber = (d) => {
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    const weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
    return weekNo;
}

export const mapFileNameToUrl = (fileName) => {
    const mappingArray = [
        { fileName: 's3://aws-bedrock-kb-bucket/wellarchitected-framework.pdf', publicUrl: 'https://docs.aws.amazon.com/wellarchitected/latest/framework/welcome.html' },
        { fileName: 's3://aws-bedrock-kb-bucket/aws-overview.pdf', publicUrl: 'https://docs.aws.amazon.com/whitepapers/latest/aws-overview/introduction.html' },
        { fileName: 's3://aws-bedrock-kb-bucket/aws-s3-best-practice.pdf', publicUrl: 'https://aws.amazon.com/s3/whitepaper-best-practices-s3-performance/'},
        { fileName: 's3://aws-bedrock-kb-bucket/aws-best-practices-ddos-resiliency.pdf', publicUrl: 'https://docs.aws.amazon.com/whitepapers/latest/aws-best-practices-ddos-resiliency/aws-best-practices-ddos-resiliency.html'},
        { fileName: 's3://aws-bedrock-kb-bucket/devops-guidance.pdf', publicUrl: 'https://docs.aws.amazon.com/wellarchitected/latest/devops-guidance/devops-guidance.html'},
        { fileName: 's3://aws-bedrock-kb-bucket/government-lens.pdf', publicUrl: 'https://docs.aws.amazon.com/wellarchitected/latest/government-lens/government-lens.html'},
        { fileName: 's3://aws-bedrock-kb-bucket/hybrid-connectivity.pdf', publicUrl: 'https://docs.aws.amazon.com/whitepapers/latest/building-scalable-secure-multi-vpc-network-infrastructure/hybrid-connectivity.html'},
        { fileName: 's3://aws-bedrock-kb-bucket/migration-lens.pdf', publicUrl: 'https://docs.aws.amazon.com/wellarchitected/latest/migration-lens/migration-lens.html'},
        { fileName: 's3://aws-bedrock-kb-bucket/running-containerized-microservices.pdf', publicUrl: 'https://docs.aws.amazon.com/whitepapers/latest/running-containerized-microservices/introduction.html'},
        { fileName: 's3://aws-bedrock-kb-bucket/wellarchitected-iot-lens.pdf', publicUrl: 'https://docs.aws.amazon.com/wellarchitected/latest/iot-lens/abstract-and-introduction.html'},
        { fileName: 's3://aws-bedrock-kb-bucket/wellarchitected-machine-learning-lens.pdf', publicUrl: 'https://docs.aws.amazon.com/wellarchitected/latest/machine-learning-lens/machine-learning-lens.html'},
        { fileName: 's3://aws-bedrock-kb-bucket/wellarchitected-reliability-pillar.pdf', publicUrl: 'https://docs.aws.amazon.com/wellarchitected/latest/reliability-pillar/welcome.html'},
      ];
    const mapping = mappingArray.find(map => map.fileName === fileName);
    return mapping ? mapping.publicUrl : null;
}
  