import * as Action from "../redux/generation_reducer";
import * as UserAction from "../redux/user_reducer"

import { postServerDataWithTokenV2, getServerDataWithToken } from "../helper/helper.js";

export const generateInsight =
  (token, results, questionIds) => async (dispatch) => {
    try {
      if (!token) throw new Error("Not authenticated");
      await postServerDataWithTokenV2(
        `${process.env.REACT_APP_SERVER_HOSTNAME}/api/generateInsight`,
        { results, questionIds },
        token
      ).then((data) => {
        try {
          let parsed = JSON.parse(data.parsedResponse.content[0].text);
          if (!Array.isArray(parsed)) {
            parsed = [parsed];
          }
          dispatch(
            Action.setInsight({
              insight: parsed,
            })
          );
        } catch (error) {
          throw new Error("Error parsing response");
        }
      });
    } catch (error) {
      console.log(error);
      let errorMessage = error.message;

      if (errorMessage === "Not authenticated") {
        dispatch(
          Action.setInsight({
            insight: [{ insight: "In order to get access to insights you will need to be logged in. If you're interested in trying this capability consider purchasing a monthly subscription or reach out to support via the learn more link.", reference: "mailto:support@cloudcertificationprep.com" }],
          })
        );
      } else if (errorMessage === "Error parsing response") {
        dispatch(
          Action.setInsight({
            insight: [{ insight: "Error generating insight", reference: "#" }],
          })
        );
      } else {
        dispatch(
          Action.setInsight({
            insight: [{ insight: "Error generating insight", reference: "#" }],
          })
        );
      }
    }
  };

  export const publishInsight = async (insightData, token) => {
    const { userName, resultId, insights } =  insightData
    try {
        if (!token) throw new Error("Not authenticated")
        if(!userName || !resultId || !insights) throw new Error("Couldn't get Insights")
        await postServerDataWithTokenV2(`${process.env.REACT_APP_SERVER_HOSTNAME}/api/storeInsight`, insightData, token, data => data)
    } catch(error) {
        console.log(error)
    }
}

export const getInsights = (username, token) => async (dispatch) => {
  try {
      await getServerDataWithToken(`${process.env.REACT_APP_SERVER_HOSTNAME}/api/user/insights`, username, token)
      .then(data => {
        dispatch(UserAction.setUserInsights(data))
      })
  } catch (error) {
      console.log(error)
  }
}

export const updateInsights =
  (userName, insights, token) => async (dispatch) => {
    try {
      if (!token) throw new Error("Not authenticated");
      if (!userName || !insights)
        throw new Error("Couldn't get Insights");
      await postServerDataWithTokenV2(
        `${process.env.REACT_APP_SERVER_HOSTNAME}/api/user/insights`,
        {userName, insights},
        token,
        (data) => data
      );
    } catch (error) {
      console.log(error);
    }
  };
