import React from "react"
import ReactMarkdown from "react-markdown"

let Answer = (props) => {
    return (
      <div
        onClick={() => props.onSelect(props.id)}
        key={props.id}
        className={`group box-border relative w-full bg-white border-solid border-2 border-transparent hover:border-solid hover:border-2 hover:border-blue-600 rounded-md drop-shadow cursor-pointer hover:box-border
            ${
              props.result[props.trace] === props.id
                ? "border-solid border-2 border-blue-600"
                : ""
            }
            ${
              props.checked === props.id
                ? "border-solid border-2 border-blue-600"
                : ""
            }
          `}
      >
        <div className="my-2 mx-2 py-2 px-2 flex justify-between">
          <div>
            <p className="text-sm text-gray-700">
              <ReactMarkdown
                className="prose max-w-none"
                children={props.name}
              ></ReactMarkdown>
            </p>
          </div>
        </div>
      </div>
    );
}

export default Answer