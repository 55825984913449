import * as Action from "../redux/user_reducer"

import { postServerDataWithToken, getServerDataWithToken } from "../helper/helper.js"

export const UpdateUser = (username, token) => async (dispatch) => {
    try {
      if(!username) throw new Error("Couldn't get User")
        await postServerDataWithToken(`${process.env.REACT_APP_SERVER_HOSTNAME}/api/user`, username, token, data => data)
        dispatch(Action.setUsername(username))
    } catch (error) {
        console.log(error)
    }
}

export const UpdateUserResult = (username, token) => async (dispatch) => {
    try {
        await getServerDataWithToken(`${process.env.REACT_APP_SERVER_HOSTNAME}/api/user/result`, username, token)
        .then(data => {
          dispatch(Action.setUserResults(data))
        })
    } catch (error) {
        console.log(error)
    }
}

export const setUserAuth = (auth) => async (dispatch) => {
    try {
        dispatch(Action.setUserAuth(auth))
    } catch(error){
        console.log(error)
    }
}
