import { createSlice } from "@reduxjs/toolkit"

export const userReducer = createSlice({
  name: "user",
  initialState: {
    username: null,
    auth: null,
    result: null,
    insights: null,
  },
  reducers: {
    setUsername: (state, action) => {
      state.username = action.payload;
    },
    setUserResults: (state, action) => {
      state.result = action.payload;
    },
    setUserAuth: (state, action) => {
      state.auth = action.payload;
    },
    setUserInsights: (state, action) => {
      state.insights = action.payload;
    },
    setActiveInsight: (state, action) => {
        state.insights = state.insights.map((item) => {
          item.insights = item.insights.map((insight) => {
            if (insight.insight === action.payload.insight) {
              return {
                ...insight,
                active: false,
              };
            }
            return insight;
          });
          return item;
        });
      },
  },
});

export const { setUsername, setUserResults, setUserAuth, setUserInsights, setActiveInsight } = userReducer.actions
export default userReducer.reducer