import { createSlice } from "@reduxjs/toolkit"

export const generationReducer = createSlice({
    name: "generation",
    initialState : {
        questionGeneration : null,
        insightGeneration : null
    },
    reducers : {
        setGenerations : (state, action) => {
            state.questionGeneration = action.payload
        },
        setInsight : (state, action) => {
            state.insightGeneration = action.payload
        },
        resetInsights: () => {
            return {
                insightGeneration : []
            }
        }
    }
})

export const { setGenerations, setInsight, resetInsights } = generationReducer.actions
export default generationReducer.reducer