import React from "react"
import ReactMarkdown from "react-markdown"

let AnswerReview = (props) => {
    return(
      <div
        className={`group relative w-full bg-white rounded-md drop-shadow cursor-pointer disabled
        ${props.answers[props.questionId ] === props.id && props.answers[props.questionId] === props.id ? "border-solid border-2 border-green-600" : ""}
        ${props.result[props.questionId] === props.id ? "border-solid border-2 border-blue-600" : ""} 
        `}
      >
        <div className="my-2 mx-2 py-2 px-2 flex justify-between">
          <div>
              <p className="text-sm text-gray-700">
                <ReactMarkdown>
                  {props.name}
                </ReactMarkdown>
              </p>
          </div>
        </div>
      </div>
    )
}

export default AnswerReview
