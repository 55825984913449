import React, { Fragment } from "react"

import { Dialog, Transition } from "@headlessui/react"
import { TrophyIcon } from "@heroicons/react/24/outline"
import { resetAllAction } from "../../redux/question_reducer"
import { resetResultAction } from "../../redux/result_reducer"

import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

let FeedbackModal = (props) => {
  const dispatch = useDispatch()

  const onRestart = () => {
    dispatch(resetAllAction())
    dispatch(resetResultAction())
    window.location.href = 'https://cloudcertificationprep.com';
  }
  return (
    <Transition.Root show={props.displayModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={props.setDisplayModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                      <TrophyIcon
                        className="h-6 w-6 text-blue-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Thank you for completing the demo!
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          We are working hard to create an amazing test prep
                          experience for everyone who is trying to break into IT
                          or looking to enhance their existing skill set. If
                          you're interested in being part of this journey we
                          would love your feedback! Feel free to tell us what
                          your experience was like or leave your email to stay
                          plugged into our weekly updates.
                        </p>
                        <div>
                          <label
                            htmlFor="feedback"
                            className="block text-sm font-medium text-gray-900 pt-2"
                          >
                            Feedback
                          </label>
                          <div className="mt-1">
                            <p className="text-sm text-gray-500">
                              <a href="https://forms.gle/mV7UAcGKGHwc2gZk9" target="_blank">https://forms.gle/mV7UAcGKGHwc2gZk9</a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => onRestart()}
                  >
                    Finish
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => props.setDisplayModal(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default FeedbackModal;
