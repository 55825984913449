import React, { useState, useEffect } from "react"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from "react-chartjs-2"

ChartJS.register(ArcElement, Tooltip, Legend)

export const options = {
    plugins: {
        legend: {
            display: false,
            position: 'bottom'
        }
    },
    cutout: "60%"
}

let ResultGraphic = (props) => {
    const [chartData, setChartData] = useState({
        labels: ['Correct','Incorrect','Not Answered'],
        datasets: [
            {
                label: 'Answers',
                data: [props.userScore, props.testLength - props.userScore, 0],
                backgroundColor: [
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                ],
                borderColor: [
                    'rgba(75, 192, 192, 1)',
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                ],
                borderWidth: 1,
            }
        ]
    })

    useEffect(() => {
        setChartData({
            labels: ['Correct','Incorrect','Not Answered'],
            datasets: [
                {
                    label: 'Answers',
                    data: [props.userScore, props.testLength - props.userScore, 0],
                    backgroundColor: [
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                    ],
                    borderColor: [
                        'rgba(75, 192, 192, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                    ],
                    borderWidth: 1,
                }
            ]
        })
    }, [props.userScore, props.testLength, 0])

    return(
        <div className="px-2 py-2">
            <Doughnut 
                data={chartData}
                options={options}
            />
        </div>
    )
}

export default ResultGraphic