import { createSlice } from "@reduxjs/toolkit"

export const initReducer = createSlice({
    name: "init",
    initialState : {
        examName: "",
        questionType: null,
        certificationType: null,
        difficulty: null,
        examLength: null,
        examTopics: [],
    },
    reducers : {
        initExam : (state, action) => {
            const { examName, certificationType, difficulty, examLength, questionType } = action.payload
            const examTopics = action.payload.topic
            state.examName = examName
            state.certificationType = certificationType
            state.difficulty = difficulty
            state.examLength = examLength
            state.examTopics = examTopics
            state.questionType = questionType
        }
    }
})

export const { initExam } = initReducer.actions
export default initReducer.reducer