import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { useSelector, useDispatch } from "react-redux";
import { useCookies } from "react-cookie";

import Answer from "./global/Answers";

import { pushQuestion, pullQuestion } from "../hooks/PushQuestions";
import { generateQuestion, generateQuestionFromAgent } from "../hooks/GenerateQuestion";

import { mapFileNameToUrl } from "../helper/utils.js";

let question = {
  questionId: "TD_002",
  questionType: "Declarative",
  questionText: "Sample Text",
  options: ["Option 1", "Option 2", "Option 3", "Option 4"],
  difficulty: "easy",
  explanation: "Explanation 4",
  topic: "Billing",
  certificationType: "AWS Certified Cloud Practitioner",
  correctOptions: 1,
};

const InternalView = () => {

  const state = useSelector((state) => state);

  const [isLoading, setIsLoading] = useState(false);

  const [questionId, setQuestionId] = useState(
    state.generation.questionGeneration?.text.questionId ? state.generation.questionGeneration?.text.questionId : question.questionId
  )

  const [correctOption, setCorrectOption] = useState(question.correctOptions)

  const [questionType, setQuestionType] = useState(question.questionType)

  const [difficulty, setDifficulty] = useState(
    state.generation.questionGeneration?.text.difficulty
  )

  const [topic, setTopic] = useState(question.topic)

  const [questionText, setQuestionText] = useState(
    state.generation.questionGeneration?.text.questionText
  )

  const [options, setOptions] = useState(question.options)

  const [explanation, setExplanation] = useState(
    state.generation.questionGeneration?.text.explantion && state.generation.questionGeneration.text.reference ? `${state.generation.questionGeneration?.text.explanation} ${state.generation.questionGeneration?.text?.reference.map(ref => `\n\n **${ref.sourceDocumentName}** \n\n ${ref.content}`).join(', ')}` : `${state.generation.questionGeneration?.text.explanation}`
  )

  const [references, setReferences] = useState(state.generation.questionGeneration?.text.reference ? state.generation.questionGeneration?.text.reference : [])

  const [citation, setCitation] = useState(
    state.generation.questionGeneration?.citation
  )

  const [questionPull, setQuestion] = useState(question)

  const [uri, setUri] = useState([])

  const dispatch = useDispatch();
  const [cookies] = useCookies(["accessToken"]);

  useEffect(() => {
    console.log(questionPull);
  }, [questionPull]);

  const handleQuestionIdChange = (event) => {
    setQuestionId(event.target.value);
  }

  useEffect(() => {
    setQuestionId(state.generation.questionGeneration?.text.questionId ? state.generation.questionGeneration?.text.questionId : questionId);
    setDifficulty(state.generation.questionGeneration?.text.difficulty);
    setQuestionText(state.generation.questionGeneration?.text.questionText);
    setOptions(state.generation.questionGeneration?.text.options ? state.generation.questionGeneration?.text.options : question.options)
    setExplanation(state.generation.questionGeneration?.text.explantion && state.generation.questionGeneration.text.reference ? `${state.generation.questionGeneration?.text.explanation} ${state.generation.questionGeneration?.text?.reference.map(ref => `\n\n **${ref.sourceDocumentName}** \n\n ${ref.content}`).join(', ')}` : `${state.generation.questionGeneration?.text.explanation}`);
    setReferences(state.generation.questionGeneration?.text.reference);
    setCitation(state.generation.questionGeneration?.citation);
    setUri(`\n\n **References** \n\n ${pullReferenceURI()?.map(uri_map => `[${mapFileNameToUrl(uri_map)}](${mapFileNameToUrl(uri_map)}) \n\n`)}`);
  }, [state.generation.questionGeneration, citation]);

  const updateQuestion = () => {
    setQuestion({
      ...question,
      questionId: questionId,
      questionType: questionType,
      difficulty: difficulty,
      topic: topic,
      correctOptions: correctOption,
      questionText: questionText,
      options: options,
      explanation: explanation + uri,
    });
  };

  const submitQuestion = () => {
    const token = cookies.accessToken;
    if (token) {
      try {
        dispatch(
          pushQuestion(
            {
              ...question,
              questionId: questionId,
              questionType: questionType,
              difficulty: difficulty,
              topic: topic,
              correctOptions: correctOption,
              questionText: questionText,
              options: options,
              explanation: explanation + uri,
            },
            cookies.accessToken
          )
        );
      } catch (error) {
        console.error("Error in pushQuestion:", error);
      }
    } else {
      console.log("No tokens found");
    }
  }

  const submitGenerateQuestionV2 = async () => {
    setIsLoading(true)
    const token = cookies.accessToken;
    if (token) {
      try {
        await dispatch(generateQuestionFromAgent(cookies.accessToken))
        setIsLoading(false)
      } catch (error) {
        console.error("Error in pushQuestion:", error);
        setIsLoading(false) // Ensure loading state is reset even if an error occurs
      }
    } else {
      console.log("No tokens found");
      setIsLoading(false) // Ensure loading state is reset even if no token is found
    }
  }

  const submitGenerateQuestion = () => {
    const token = cookies.accessToken;
    if (token) {
      try {
        dispatch(generateQuestion(cookies.accessToken));
      } catch (error) {
        console.error("Error in pushQuestion:", error);
      }
    } else {
      console.log("No tokens found");
    }
  };

  const pullQuestionData = () => {
    console.log(`Fetching data for question ${questionId}`);
    const token = cookies.accessToken;
    if (token) {
      try {
        dispatch(
          pullQuestion(
            questionId,
            cookies.accessToken
          )
        ).then(result => {
          setQuestionText(result[0].questionText)
          setDifficulty(result[0].difficulty);
          setOptions(result[0].options)
          setExplanation(result[0].explanation);
          setTopic(result[0].topic);
          setCorrectOption(result[0].correctOptions);
          setQuestionType(result[0].questionType);
        })
      } catch (error) {
        console.error("Error in pullQuestion:", error);
      }
    } else {
      console.log("No tokens found");
    }
  }

  const pullReferenceURI = () => {
    if (citation) {
      let uris = citation
        .filter(
          (citation) =>
            citation.orchestrationTrace?.observation?.type === "KNOWLEDGE_BASE"
        )
        .flatMap((citation) =>
          citation.orchestrationTrace?.observation?.knowledgeBaseLookupOutput?.retrievedReferences.map(
            (ref) => ref.location.s3Location.uri
          )
        );
      return uris = [...new Set(uris)];

    } else {
      console.log("citation not found");
    }
  };

  return (
    <div>
      <div className="px-4 py-2 mx-8 mb-8 my-2 bg-white drop-shadow">
        <h1 className="font-large leading-tight text-2xl mt-2 mb-2 text-black">
          Actions
        </h1>
        <div className="flex items-center my-2">
        <button
          onClick={submitGenerateQuestionV2}
          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          Generate Question
        </button>
        {isLoading && (
          <div className="flex items-center m-2">
            <div className="w-8 h-8 border-t-4 border-blue-500 rounded-full animate-spin mx-2"></div>
            <p>Loading...</p>
          </div>
        )}
        </div>
        <button
          onClick={pullQuestionData}
          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          Pull Existing Question
        </button>
        <button
          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          onClick={updateQuestion}
        >
          Log Question Object
        </button>
        <button
          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          onClick={submitQuestion}
        >
          Push Question
        </button>
        <p className="font-medium leading-tight text-m mt-6 mb-2 text-black">
          Question ID
        </p>
        <input
          id="question-id"
          className="inline-flex border-solid border-2 border-gray-300 p-2 rounded-md"
          value={questionId}
          onChange={handleQuestionIdChange}
        />
      </div>
      <div className="flex">
        <div className="w-1/2">
          <h1 className="font-large leading-tight text-2xl mt-12 mb-2 text-black">
            EDITING
          </h1>
          <div class="px-4 py-2 mx-4 my-2 bg-white drop-shadow">
            <label
              htmlFor="question-id"
              className="block text-sm font-medium text-gray-700"
            >
              Question ID
            </label>
            <input
              type="text"
              name="question-id"
              id="question-id"
              autoComplete="td-001"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              onChange={(e) => setQuestionId(e.target.value)}
              value={questionId}
            />
            <label
              htmlFor="question-id"
              className="block text-sm font-medium text-gray-700 mt-6"
            >
              Question Type
            </label>
            <input
              type="text"
              name="question-type"
              id="question-type"
              autoComplete="Declarative"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              onChange={(e) => setQuestionType(e.target.value)}
              value={questionType}
            />
            <label
              htmlFor="difficulty"
              className="block text-sm mt-6 font-medium text-gray-700"
            >
              Select the difficulty level
            </label>
            <select
              id="difficulty"
              name="difficulty"
              autoComplete="difficulty-level"
              onChange={(e) => setDifficulty(e.target.value)}
              className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
            >
              <option value="easy">Easy</option>
              <option value="medium">Medium</option>
              <option value="hard">Hard</option>
            </select>
            <label
              htmlFor="answer"
              className="block text-sm mt-6 font-medium text-gray-700"
            >
              Select the answer
            </label>
            <select
              id="answer"
              name="answer"
              autoComplete="difficulty-level"
              onChange={(e) => setCorrectOption(e.target.value)}
              value={correctOption}
              className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
            >
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
            </select>
            <label
              htmlFor="exam-topic"
              className="block text-sm mt-6 font-medium text-gray-700"
            >
              Topic
            </label>
            <input
              type="text"
              name="exam-topic"
              id="exam-topic"
              autoComplete="billing"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              onChange={(e) => setTopic(e.target.value)}
              value={topic}
            />
            <label
              htmlFor="question-text"
              className="block text-sm mt-6 font-medium text-gray-700"
            >
              Question Text
            </label>
            <textarea
              value={questionText ? questionText : ""}
              id="question-text"
              onChange={(e) => setQuestionText(e.target.value)}
              className="w-full border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            />
            <label className="block text-sm mt-6 font-medium text-gray-700">
              Question Options
            </label>
            <div class="mx-4 my-2">
              <div className="mx-auto max-w-2xl lg:max-w-7xl">
                <div className="my-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-2 xl:gap-x-8">
                  {options
                    ? options.map((option, i) => (
                        <div className="w-full">
                          <textarea
                            value={option ? option : ""}
                            onChange={(e) => {
                              setOptions(
                                options.map((option, index) =>
                                  index === i ? e.target.value : option
                                )
                              );
                            }}
                            className="w-full border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          />
                        </div>
                      ))
                    : null}
                </div>
              </div>
            </div>
            <label
              htmlFor="question-explanation"
              className="block text-sm mt-6 font-medium text-gray-700"
            >
              Explanation
            </label>
            <textarea
              id="question-explanation"
              value={explanation ? explanation : ""}
              onChange={(e) => setExplanation(e.target.value)}
              className="w-full border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            />
            <textarea
              id="question-uri"
              value={uri ? uri : ""}
              onChange={(e) => setUri(e.target.value)}
              className="w-full border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            />
          </div>
        </div>
        <div className="w-1/2">
          <h1 className="font-large leading-tight text-2xl mt-12 mb-2 text-black">
            RENDER
          </h1>
          <div class="flex flex-col md:flex-row w-10/12">
            <div class="column-2 w-full md:w-full">
              <div class="px-4 py-2 mx-4 my-2 bg-white drop-shadow border-gray-300 shadow-sm sm:text-sm">
                <ReactMarkdown
                  className="prose max-w-none border-gray-300 shadow-sm mt-6"
                  children={questionText ? questionText : "Sample\n\n**Text**"}
                ></ReactMarkdown>
                <div class="mx-4 my-2">
                  <div className="mx-auto max-w-2xl lg:max-w-7xl">
                    <div className="my-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-2 xl:gap-x-8">
                      {options
                        ? options.map((option, i) => (
                            <Answer
                              question={option}
                              key={i}
                              id={i}
                              name={option}
                              onSelect={true}
                              result={1}
                              trace={true}
                              checked={true}
                            />
                          ))
                        : null}
                    </div>
                  </div>
                </div>
                <ReactMarkdown
                  className="prose max-w-none border-gray-300 shadow-sm mt-6"
                  children={
                    explanation && uri
                      ? explanation + uri
                      : "Sample\n\n**Text**"
                  }
                ></ReactMarkdown>
              </div>

              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InternalView;
