import React, { useEffect } from "react";
import { useSelector } from "react-redux"


import {
    QuestionMarkCircleIcon,
    CheckCircleIcon,
    ExclamationCircleIcon,
  } from "@heroicons/react/24/outline"


let QuestionSideBar = (props) => {

  const result = useSelector(state => state.result.result)

  if (result.length > 0 && result[props.index] != null) {
    return (
      <div onClick={() => props.jumpToQuestion(props.index)} class={`flex justify-center md:justify-start py-4 my-4 md:px-4 md:mx-4 hover:bg-blue-700 hover:cursor-pointer rounded-md ${props.question === props.index ? "" : "bg-blue-700"}`}>
        <CheckCircleIcon className="h-6 w-6 text-white" aria-hidden="true" />
        <div class="ml-4 text-xs md:text-sm text-white hidden md:block">
          Question {props.index + 1}
        </div>
      </div>
    )
  } else if (props.status === "Flagged") { /** Not implemented currently. */
    return (
      <div onClick={() => props.jumpToQuestion(props.id)} class={`flex justify-center md:justify-start py-4 my-4 md:px-4 md:mx-4 hover:bg-blue-700 hover:cursor-pointer rounded-md ${props.question === props.id ? "" : "bg-blue-700"}`}>
        <ExclamationCircleIcon className="h-6 w-6 text-white" aria-hidden="true" />
        <div class={`ml-4 text-xs md:text-sm text-white hidden md:block`}>
          Question {props.id}
        </div>
      </div>
    )
  } else {
    return (
      <div onClick={() => props.jumpToQuestion(props.index)} class={`flex justify-center md:justify-start py-4 my-4 md:px-4 md:mx-4 hover:bg-blue-700 hover:cursor-pointer rounded-md ${props.question === props.index ? "bg-blue-700" : ""}`}>
        <QuestionMarkCircleIcon className="h-6 w-6 text-white" aria-hidden="true" />
        <div class={`ml-4 text-xs md:text-sm text-white hidden md:block`}>
          Question {props.index + 1}
        </div>
      </div>
    )
  }
}

export default QuestionSideBar