import React from "react"
import ReactMarkdown from "react-markdown"

import AnswerReview from "./AnswersReview"

// import data, { explanations } from "../../config/demo/data"

let ReviewAnswer = (props) => {

  return (
    <div className="flex flex-col md:flex-row">
      <div className="column-2 w-full md:w-full">
        <div className="mx-4 my-2">
          <h3 className="font-medium leading-tight text-2xl mt-0 mb-2 text-black">
            Question{" "}
            {
              props.id + 1
            }
          </h3>
        </div>
        <div class="px-4 py-2 mx-4 my-2 bg-white drop-shadow">
          <ReactMarkdown
            className="prose max-w-none"
            children={
              props.question.questionText
            }
          ></ReactMarkdown>
        </div>
        <div className="mx-4 my-2">
          <div className="mx-auto max-w-2xl lg:max-w-7xl">
            <div className="my-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-2 xl:gap-x-8">
              {props.question.options.map((option, i) => (
                <AnswerReview key={i} id={i} name={option} questionId={props.id} answers={props.answers} result={props.userAnswers}/>
              ))}
            </div>
          </div>
        </div>
        <div className="px-4 py-2 mx-4 my-2 bg-white drop-shadow">
          <ReactMarkdown
            className="prose max-w-none"
            children={
              props.explanation
            }
          ></ReactMarkdown>
        </div>
      </div>
    </div>
  );
};

export default ReviewAnswer