import { combineReducers, configureStore } from "@reduxjs/toolkit"

import initReducer from "./initialization_reducer"
import questionReducer from "./question_reducer"
import resultReducer from "./result_reducer"
import userReducer from "./user_reducer"
import generationReducer from "./generation_reducer"

const rootReducer = combineReducers({
    init: initReducer,
    questions: questionReducer,
    result: resultReducer,
    user: userReducer,
    generation: generationReducer
})

export default configureStore({ reducer: rootReducer })