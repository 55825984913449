import { Fragment } from "react";
import { Link } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { useCookies } from 'react-cookie'

import { resetAllAction } from "../redux/question_reducer"
import { resetResultAction } from "../redux/result_reducer"

import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

import ccpLogo from "../images/logo/ccp-logo.png";

const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

const NavBar = () => {

  const role = useSelector(state => state.user.auth?.role ? state.user.auth?.role[0] : null)
  const [cookies, removeCookie] = useCookies(['accessToken']);

  const dispatch = useDispatch()

  let navigation = [
    { name: "Reset", href: "/test-config", current: true },
    { name: "Login", href: `https://login.cloudcertificationprep.com/oauth2/authorize?client_id=6kk3uee1uflemfg9u31vko4etl&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=${encodeURIComponent(process.env.REACT_APP_CLIENT_HOSTNAME + "/cognito-redirect")}`, current: true }
  ]
  let authenticatedNavigation = [
    { name: "Launch Exam", href: "/test-config", current: true },
    { name: "Logout", href: "/", current: true },
  ]
  let adminNavigation = [
    { name: "Launch Exam", href: "/test-config", current: true },
    { name: "Internal", href: "/internal", current: true },
    { name: "Logout", href: "/", current: true },
  ]
  
  if ((role && role === "user")) {
    navigation = authenticatedNavigation
  }

  if ((role && role === "admin")) {
    navigation = adminNavigation
  }

  const handleResetClick = () => {
    dispatch(resetAllAction())
    dispatch(resetResultAction())
  }

  const handleLogoutClick = () => {
    removeCookie('accessToken')
    removeCookie('refreshToken')
    removeCookie('idToken')
    window.location.href = `https://login.cloudcertificationprep.com/logout?response_type=code&client_id=6kk3uee1uflemfg9u31vko4etl&logout_uri=https%3A%2F%2Fcloudcertificationprep.com`
    return false
  }

  return (
    <>
      <Disclosure as="nav" className="bg-white">
        {({ open, close }) => (
          <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="flex h-16 items-center justify-between">
                <div className="flex justify-between items-center w-full">
                  <div className="flex-shrink-0">
                    <Link to="/home">
                      <img
                        className="h-8 w-16"
                        src={ccpLogo}
                        alt="Cloud Certification Prep"
                      />
                    </Link>
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-10 flex items-baseline space-x-4">
                      {navigation.map((item) =>
                        item.href.startsWith("http") ? (
                          <a
                            href={item.href}
                            className={classNames(
                              item.current
                                ? "bg-blue-600 text-white"
                                : "bg-blue-500 text-white hover:bg-blue-600",
                              "px-3 py-2 rounded-md text-sm font-medium"
                            )}
                          >
                            {item.name}
                          </a>
                        ) : (
                          <Link
                            to={item.href}
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              item.current
                                ? "bg-blue-600 text-white"
                                : "bg-blue-500 text-white hover:bg-blue-600",
                              "px-3 py-2 rounded-md text-sm font-medium"
                            )}
                            aria-current={item.current ? "page" : undefined}
                            onClick={
                              item.name === "Reset"
                                ? handleResetClick
                                : item.name === "Logout"
                                ? handleLogoutClick
                                : undefined
                            }
                          >
                            {item.name}
                          </Link>
                        )
                      )}
                    </div>
                  </div>
                </div>
                <div className="hidden md:block">
                  <div className="ml-4 flex items-center md:ml-6">
                    {/* Profile dropdown */}
                    <Menu as="div" className="relative ml-3">
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      ></Transition>
                    </Menu>
                  </div>
                </div>
                <div className="-mr-2 flex md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-blue-600 p-2 text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
                {navigation.map((item) =>
                  item.href.startsWith("http") ? (
                    <a
                      key={item.name}
                      as="a"
                      href={item.href}
                      className={classNames(
                        item.current
                          ? "bg-blue-600 text-white"
                          : "text-slate-800 hover:bg-blue-500 hover:text-white",
                        "block px-3 py-2 rounded-md text-base font-medium"
                      )}
                      aria-current={item.current ? "page" : undefined}
                      onClick={close}
                    >
                      {item.name}
                    </a>
                  ) : (
                    <Link
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        item.current
                          ? "bg-blue-600 text-white"
                          : "text-slate-800 hover:bg-blue-500 hover:text-white",
                        "block px-3 py-2 rounded-md text-base font-medium"
                      )}
                      aria-current={item.current ? "page" : undefined}
                      onClick={
                        item.name === "Logout" ? handleLogoutClick : close
                      }
                    >
                      {item.name}
                    </Link>
                  )
                )}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
};

export default NavBar;
