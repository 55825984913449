import qs from "qs"
import axios from "axios"

export const FetchAuthTokens = async (code, callback) => {
    const requestBody = {
        grant_type: 'authorization_code',
        client_id: '6kk3uee1uflemfg9u31vko4etl',
        code,
        redirect_uri: `${process.env.REACT_APP_CLIENT_HOSTNAME}/cognito-redirect`,
    };

    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        auth: {
            username: '6kk3uee1uflemfg9u31vko4etl',
            password: '1b528p49dj8pff8cjk43057765p6g7qv8oql5fndumhrepkpus30'
        }
    }

    try {
        const response = await axios.post('https://login.cloudcertificationprep.com/oauth2/token', qs.stringify(requestBody), config)
        return response.data;
    } catch (error) {
        console.error('Error getting tokens:', error);
        return { error: error.response ? error.response.data : 'Unknown error' };
    }
}