import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'

const Paginator = (props) => {

  const totalPageNumbers = props.pageNumbers;
  const currentPage = props.currentPage;

  const visiblePageNumbers = totalPageNumbers.reduce((acc, number, index) => {
    if (index < 3 || index > totalPageNumbers.length - 4) {
      acc.push(number);
    }
    else if (
      number === currentPage ||
      number === currentPage - 1 ||
      number === currentPage + 1
    ) {
      acc.push(number);
    }
    else if (acc[acc.length - 1] !== "...") {
      acc.push("...");
    }

    return acc;
  }, []);

  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
      <div className="flex flex-1 justify-between sm:hidden">
        <a
          onClick={props.handlePreviousButtonClick}
          className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          Previous
        </a>
        <a
          onClick={props.handleNextButtonClick}
          className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          Next
        </a>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{(props.currentPage - 1) * props.resultsPerPage + 1}</span> to{" "}
            <span className="font-medium">{Math.min(props.currentPage * props.resultsPerPage, props.totalResults)}</span> of{" "}
            <span className="font-medium">{props.totalResults}</span> results
          </p>
        </div>
        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <a
              onClick={props.handlePreviousButtonClick}
              className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </a>
            {/* Current: "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600", Default: "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0" */}
            {visiblePageNumbers.map((number) => (
              <a
                key={number}
                id={number}
                onClick={props.handlePageClick}
                aria-current="page"
                className={`relative z-10 inline-flex items-center px-4 py-2 text-sm font-semibold focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 border border-gray-300 ${number === props.currentPage ? 'bg-blue-600 text-white' : 'bg-white text-blue-600'}`}
                >
                {number}
              </a>
            ))}
            <a
              onClick={props.handleNextButtonClick}
              className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </a>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default Paginator;