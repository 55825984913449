import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

import { useCookies } from "react-cookie";

import { resetAllAction } from "../redux/question_reducer"
import { resetResultAction } from "../redux/result_reducer"
import { resetInsights } from "../redux/generation_reducer"


import ResultGraphic from "./global/ResultGraphic"
import ReviewAnswer from "./global/ReviewAnswer"
import FeedbackModal from "./global/FeedbackModal"

import { attemptedQuestions, earnedPoints, flagResult } from "../helper/helper"
import { publishResult } from "../hooks/SetResult"
import { generateInsight, publishInsight } from "../hooks/GenerateInsight"

import { LightBulbIcon } from "@heroicons/react/24/outline"
import ReactMarkdown from "react-markdown"

let ResultView = (props) => {

  const state = useSelector(state => state)
  const dispatch = useDispatch()
  const [cookies] = useCookies(["accessToken"]);

  const [resultId, setResultId] = useState(process.env.NODE_ENV === "production" ? Math.floor(Math.random()*100) : `dev-${Math.floor(Math.random()*1000)}`)
  const [insights, setInsights] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const {questions: {queue}, result: {result, userId}} = useSelector(state => state)
  const answers = queue.map((question) => (
    question.correctOptions
  ))

  const examType = state.init.certificationType

  const examName = useSelector(state => state.init.examName)
  const navigate = useNavigate()

  const totalPoints = queue.length
  const attempts = attemptedQuestions(result)
  const pointsEarned = earnedPoints(result, answers, 1)
  const flag = flagResult(totalPoints, pointsEarned)

  useEffect(() => {
    publishResult({
      userName: state.user.auth?.authStatus
      ? state.user.username 
      : process.env.NODE_ENV === "production" 
        ? Math.floor(Math.random()*100) 
        : "local_testing",
      resultId: resultId,
      questionIds: state.result.questionIds,
      examName,
      result,
      attempts,
      points: pointsEarned,
      achieved : flag ? "Passed" : "Failed",
      examType
    })
  }, [])

  useEffect(() => {
    if (insights && insights.length > 0) {
      publishInsight({
        userName: state.user.auth?.authStatus
          ? state.user.username 
          : process.env.NODE_ENV === "production" 
            ? Math.floor(Math.random()*100) 
            : "local_testing",
        resultId: resultId,
        insights: insights
      },
      cookies.accessToken,
      )
    }
  }, [insights])


  useEffect(() => {
    if(state.generation.insightGeneration?.insight) return
    dispatch(generateInsight(
      cookies.accessToken,
      result,
      state.result.questionIds
    ))
  }, [])

  useEffect(() => {
    setInsights(state.generation.insightGeneration?.insight ? state.generation.insightGeneration.insight : [])
    setIsLoading(state.generation.insightGeneration?.insight ? false : true)
  }, [state.generation.insightGeneration])

  let [displayModal, setDisplayModal] = useState(false)
  let [reviewAnswersToggle, setReviewAnswersToggle] = useState(false)

  let toggleReviewSection = () => {
    setReviewAnswersToggle(!reviewAnswersToggle);
  }

  let toggleFeedbackModal = () => {
    setDisplayModal(!displayModal);
  }

  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
      <FeedbackModal
        displayModal={displayModal}
        setDisplayModal={setDisplayModal}
      />
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-xl font-medium leading-6 text-gray-900">
          Practice Results
        </h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          {examName ? examName : "Placeholder"}
        </p>
      </div>
      <div className="border-t border-gray-200">
        <dl>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              <ResultGraphic
                userScore={pointsEarned}
                testLength={queue.length}
              />
            </dt>
            <dd className="mt-1 pt-4 text-sm text-gray-900 sm:col-span-2 sm:mt-0 ml-4">
              <div className="bg-white">
                <div className=" mx-auto max-w-2xl py-4 px-4 sm:py-6 sm:px-6 lg:max-w-7xl lg:px-4">
                  <h3 className="text-lg font-medium my-2">
                    Attempt 1:{" "}
                    <span className={flag ? "text-green-600" : "text-red-600"}>
                      {flag ? "Passed" : "Failed"} (73% required to pass)
                    </span>
                  </h3>
                  <h3 className="text-4xl font-medium my-2">
                    {Math.round((pointsEarned / totalPoints) * 100)}%
                    <span className="text-lg">
                      {" "}
                      ({pointsEarned} of {queue.length} correct)
                    </span>
                  </h3>
                  <h3 className="text-lg font-medium my-2">
                    Completion time: {Math.round(props.duration / 60000)}{" "}
                    minutes
                  </h3>
                  <h3 className="text-lg font-medium my-2">
                    Completion date: {new Date(Date.now()).toDateString()}
                  </h3>
                </div>
              </div>
            </dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-xl font-medium text-gray-500">
              Exam Insights {isLoading}
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <ul
                role="list"
                className="divide-y divide-gray-200 rounded-md border border-gray-200"
              >
                {isLoading === true ? (
                  <div className="flex items-center m-2">
                    <div className="w-8 h-8 border-t-4 border-blue-500 rounded-full animate-spin mx-2"></div>
                    <p>Generating Insights...</p>
                  </div>
                ) : null}
                {insights ? (
                  insights.map((item, index) => (
                    <li
                      key={index}
                      className="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
                    >
                      <div className="flex w-0 flex-1">
                        <LightBulbIcon
                          className="h-5 w-5 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                        />
                        <span className="text-md ml-2 w-0 flex-1">
                            <ReactMarkdown breaks>{item.insight}</ReactMarkdown>
                        </span>
                      </div>
                      <div className="ml-4 flex-shrink-0">
                        <a
                          href={item.reference}
                          target="_blank"
                          className="font-medium text-blue-600 hover:text-blue-500"
                        >
                          Learn More
                        </a>
                      </div>
                    </li>
                  ))
                ) : (
                  <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                    <div className="flex w-0 flex-1">
                      <LightBulbIcon
                        className="h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="text-md ml-2 w-0 flex-1">
                        <p>Unavailable</p>
                        <p>Insights</p>
                      </span>
                    </div>
                  </li>
                )}
              </ul>
            </dd>
          </div>
          <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
            <button
              type="submit"
              className="inline-flex justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              onClick={() => toggleReviewSection()}
            >
              <a href="#review">Review Questions</a>
            </button>
            <button
              type="submit"
              className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              onClick={() => {
                if (state.user.auth?.authStatus) {
                  navigate("/home");
                  dispatch(resetAllAction());
                  dispatch(resetResultAction());
                  dispatch(resetInsights());
                } else {
                  toggleFeedbackModal();
                }
              }}
            >
              Finish
            </button>
          </div>
        </dl>
      </div>
      <section id="review">
        <div className={`${reviewAnswersToggle ? "" : "hidden"}`}>
          {queue.map((question, id) => (
            <ReviewAnswer
              key={id}
              question={question}
              id={id}
              userAnswers={result}
              answers={answers}
              explanation={question.explanation}
            />
          ))}
        </div>
      </section>
    </div>
  );
};

export default ResultView;
