import { useEffect, useState } from "react"

import aws from "../../images/test_logos/aws_logo.svg"
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/solid';

import Paginator from "./Paginator"

// TODO Move to helpers
const convertDate = (date) => {
  let newDate = new Date(date);
  let formattedDate = newDate.toLocaleDateString()
  return formattedDate
}

const TableOne = (props) => {

  const [currentPage, setCurrentPage] = useState(1)
  const [sortByDateOrder, setSortByDateOrder] = useState('desc')
  const [sortedResults, setSortedResults] = useState([])
  const resultsPerPage = 5

  useEffect(() => {
    if (props.userResults && props.userResults.length > 0) {
      const sorted = [...props.userResults].sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        if (dateA === dateB) {
          return 0;
        }
        return sortByDateOrder === "asc" ? dateA - dateB : dateB - dateA;
      });
      setSortedResults(sorted);
    }
  }, [sortByDateOrder, props.userResults]);

  const lastResultIndex = currentPage * resultsPerPage
  const firstResultIndex = lastResultIndex - resultsPerPage
  const currentResults = sortedResults?.slice(firstResultIndex, lastResultIndex)

  const totalPages = Math.ceil(sortedResults?.length / resultsPerPage)

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(sortedResults?.length / resultsPerPage); i++) {
    pageNumbers.push(i);
  }

  const examTypeMapping = {
    'AWS Certified Solution Architect Associate': 'SAA-C03',
    'AWS Certified Cloud Practitioner': 'AWS-CP',
  };

  const handlePageClick = (event) => {
    setCurrentPage(Number(event.target.id));
  }

  const handleNextButtonClick = () => {
    if (currentPage < totalPages) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  }
  
  const handlePreviousButtonClick = () => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  }

  const handleSortChange = () => {
    setSortByDateOrder(sortByDateOrder === 'asc' ? 'desc' : 'asc');
  }

  return (
    <div className="rounded-sm border border-stroke bg-white my-2 px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
      <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
        Exam History - Table
      </h4>

      <div className="flex flex-col">
        <div className="grid grid-cols-3 rounded-sm bg-gray-100 sm:grid-cols-4">
          <div className="p-2.5 xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base text-gray-600">
              Exam Type
            </h5>
          </div>
          <div className="p-2.5 text-center xl:p-5 sm:block hidden">
            <h5 className="text-sm font-medium uppercase xsm:text-base text-gray-600">
              Grade
            </h5>
          </div>
          <div className="p-2.5 text-center sm:block xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base text-gray-600">
              Status
            </h5>
          </div>
          <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base text-gray-600">
              Date
              <button
                onClick={handleSortChange}
                className="px-4 text-black"
              >
                {sortByDateOrder === "asc" ? (
                  <ChevronUpIcon className="h-3 w-4" />
                ) : (
                  <ChevronDownIcon className="h-3 w-4" />
                )}
              </button>
            </h5>
          </div>
        </div>
        {currentResults?.map((result, index) => (
          <div
            key={index}
            className="grid grid-cols-3 border-b border-stroke dark:border-strokedark sm:grid-cols-4"
          >
            <div className="flex items-center gap-3 p-2.5 xl:p-5">
              <div className="flex-shrink-0">
                <img src={aws} width="30" height="30" alt="Brand" />
              </div>
              <p className="text-black sm:block">
                {examTypeMapping[result.examType]}
              </p>
            </div>

            <div className="flex items-center justify-center p-2.5 xl:p-5 sm:flex hidden">
              <p className="text-black">
                {(result.points / result.attempts) * 100} %
              </p>
            </div>

            <div className="flex items-center justify-center p-2.5 sm:flex xl:p-5">
              <p
                className={
                  result.achieved === "Failed"
                    ? "text-red-500"
                    : "text-green-500"
                }
              >
                {result.achieved}
              </p>
            </div>

            <div className="flex items-center justify-center p-2.5 xl:p-5">
              <p className="text-black">{convertDate(result.createdAt)}</p>
            </div>
          </div>
        ))}
      </div>
      <Paginator
        handlePreviousButtonClick={handlePreviousButtonClick}
        handleNextButtonClick={handleNextButtonClick}
        handlePageClick={handlePageClick}
        totalResults={props.userResults?.length}
        resultsPerPage={resultsPerPage}
        currentPage={currentPage}
        pageNumbers={pageNumbers}
      />
      {/* <div>
        {pageNumbers.map(number => (
          <button key={number} id={number} onClick={handlePageClick}>
            {number}
          </button>
        ))}
      </div> */}
    </div>
  );
}

export default TableOne;
