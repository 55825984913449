import React, { useEffect, useState } from 'react';
import { UpdateUser, UpdateUserResult } from "../hooks/ManageUser"
import { getInsights } from "../hooks/GenerateInsight"
import { useDispatch, useSelector } from 'react-redux'
import { jwtDecode } from "jwt-decode"
import { useCookies } from 'react-cookie'

import TableOne from "../components/global/Table"
import DashboardChart from "../components/global/DashboardChart"
import CardList from '../components/global/CardList'

const DashboardView = () => {
  const dispatch = useDispatch()
  const [cookies] = useCookies(['accessToken'])
  const [selectedExamType, setSelectedExamType] = useState("AWS Certified Solution Architect Associate")

  const state = useSelector(state => state.user)
  const filteredResults = state.result?.filter(result => result.examType === selectedExamType)

  useEffect(() => {
    const token = cookies.accessToken
    if (token) {
      dispatch(UpdateUser(jwtDecode(token).username, cookies.accessToken))
      dispatch(UpdateUserResult(jwtDecode(token).username, cookies.accessToken))
      dispatch(getInsights(jwtDecode(token).username, cookies.accessToken))
    }
  }, [cookies, dispatch])

  const handleExamTypeChange = (event) => {
    setSelectedExamType(event.target.value);
  }

  return (
    <div className="flex flex-col h-screen bg-gray-100">
      <header className="p-4 bg-blue-600 text-white">
        <h2 className="font-semibold text-xl">Dashboard</h2>
        <h2 className="font-semibold text-xl">User: {state.username}</h2>
        <div className="inline-block relative w-auto">
          <select
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-auto pr-8 p-2.5 my-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            value={selectedExamType}
            onChange={handleExamTypeChange}
          >
            <option selected>Select an exam type</option>
            <option value="AWS Certified Solution Architect Associate">
              AWS Certified Solution Architect Associate
            </option>
            <option value="AWS Certified Cloud Practitioner">
              AWS Certified Cloud Practitioner
            </option>
          </select>
        </div>
      </header>
      <main className="flex-grow p-4">
        {state.result ? <DashboardChart userResults={filteredResults} /> : null}
        <TableOne userResults={filteredResults} />
      </main>
    </div>
  );
}

export default DashboardView