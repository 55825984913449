import { ApexOptions } from 'apexcharts';
import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

import { getWeekNumber } from '../../helper/utils'

const calculateAverageScoresByMonth = (userResults) => {
    const scoresByMonth = userResults?.reduce((acc, result) => {
    const date = new Date(result.createdAt);
    const month = date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0');
    if (!acc[month]) {
      acc[month] = [];
    }
    acc[month].push((result.points/result.attempts)*100);
    return acc;
  }, {});

  const averageScoresByMonth = Object.entries(scoresByMonth)?.map(([month, scores]) => {
    const averageScore = scores.reduce((a, b) => a + b, 0) / scores.length;
    return { month, averageScore };
  });

  averageScoresByMonth.sort((a, b) => new Date(a.month) - new Date(b.month));

  const averageScores = averageScoresByMonth.map(({ averageScore }) => averageScore);
  const months = averageScoresByMonth.map(({ month }) => month);

  return { averageScores, months }
}

const calculateAverageScoresByWeek = (userResults) => {
  const scoresByWeek = userResults?.reduce((acc, result) => {
    const date = new Date(result.createdAt);
    const weekNumber = getWeekNumber(date);
    const week = date.getFullYear() + '-W' + weekNumber;
    if (!acc[week]) {
      acc[week] = [];
    }
    acc[week].push((result.points/result.attempts)*100);
    return acc;
  }, {});

  const averageScoresByWeek = Object.entries(scoresByWeek).map(([week, scores]) => {
    const averageScore = scores.reduce((a, b) => a + b, 0) / scores.length;
    return { week, averageScore };
  });

  averageScoresByWeek.sort((a, b) => new Date(a.week) - new Date(b.week));

  const averageScores = averageScoresByWeek.map(({ averageScore }) => averageScore);
  const weeks = averageScoresByWeek.map(({ week }) => week);

  return { averageScores, weeks }
}

const ChartOne = (props) => {

    const [averageScores, setAverageScores] = useState([{}]);
    const [series, setSeries] = useState([{}]);
    const [months, setMonths] = useState([{}]);
    const [selectedTimePeriod, setSelectedTimePeriod] = useState('Week');
    
    useEffect(() => {
      const { averageScores, weeks } = calculateAverageScoresByWeek(props.userResults);
      setAverageScores(averageScores);
      setMonths(weeks);
    }, [props.userResults]);

    useEffect(() => {
      setSeries([
        {
          name: "Exam Results",
          data: averageScores,
          type: "area"
        },
        {
          name: "Exam Threshold",
          data: [72, 73, 73, 73, 73, 73, 73, 73, 73, 73, 73, 73],
          type: "line"
        },
      ]);
    }, [averageScores]);

    const dates = props.userResults?.map(result => new Date(result.createdAt));
    const minDate = new Date(Math.min.apply(null, dates));
    const maxDate = new Date(Math.max.apply(null, dates));

    // Format the dates as strings
    const minDateString = minDate.toLocaleDateString();
    const maxDateString = maxDate.toLocaleDateString();

    const handleMonthButtonClick = () => {
      const newAverageScores = calculateAverageScoresByMonth(props.userResults);
      setAverageScores(newAverageScores.averageScores);
      setMonths(newAverageScores.months)
      setSelectedTimePeriod('Month');
    }

    const handleWeeklyButtonClick = () => {
      const newAverageScores = calculateAverageScoresByWeek(props.userResults);
      setAverageScores(newAverageScores.averageScores);
      setMonths(newAverageScores.weeks)
      setSelectedTimePeriod('Week');
    }

    const options = ApexOptions = {
      legend: {
        show: false,
        position: 'top',
        horizontalAlign: 'left',
      },
      colors: ['#1976d2', '#FF9B9B'],
      chart: {
        fontFamily: 'Satoshi, sans-serif',
        height: 335,
        type: 'line',
        dropShadow: {
          enabled: true,
          color: '#623CEA14',
          top: 10,
          blur: 4,
          left: 0,
          opacity: 0.1,
        },
    
        toolbar: {
          show: false,
        },
      },
      responsive: [
        {
          breakpoint: 1024,
          options: {
            chart: {
              height: 300,
            },
          },
        },
        {
          breakpoint: 1366,
          options: {
            chart: {
              height: 350,
            },
          },
        },
      ],
      stroke: {
        width: [2, 2],
        curve: 'straight',
      },
      // labels: {
      //   show: false,
      //   position: "top",
      // },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 4,
        colors: '#fff',
        strokeColors: ['#1976d2', '#FF9B9B'],
        strokeWidth: 3,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 1,
        discrete: [],
        hover: {
          size: undefined,
          sizeOffset: 5,
        },
      },
      xaxis: {
        type: 'category',
        categories: months,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        title: {
          style: {
            fontSize: '0px',
          },
        },
        min: 0,
        max: 100,
        labels: {
          formatter: function (value) {
            return Math.floor(value);
          },
      },
    }
    };

  return (
    <div className="col-span-12 rounded-sm border border-stroke bg-white px-5 pt-7.5 pb-5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:col-span-8">
      <h4 className="my-4 text-xl font-semibold">Exam History - Chart</h4>
      <div className="flex flex-wrap items-start justify-between gap-3 sm:flex-nowrap">
        <div className="flex w-full flex-wrap gap-3 sm:gap-5">
          <div className="flex min-w-47.5 mt-4">
            <span className="mt-1 mr-2 flex h-4 w-4 max-w-4 items-center justify-center rounded-full border border-blue-500">
              <span className="block h-2.5 w-2.5 rounded-full bg-blue-600"></span>
            </span>
            <div className="w-full">
              <p className="font-semibold text-primary">Duration</p>
              <p className="text-sm font-medium">{minDateString} - {maxDateString}</p>
            </div>
          </div>
        </div>
        <div className="flex w-full max-w-45 justify-end mt-4">
          <div className="inline-flex items-center rounded-md bg-white p-1.5 dark:bg-meta-4">
            <button 
                className={`rounded mx-2 py-1 px-3 text-xs font-medium hover:text-white shadow-card ${selectedTimePeriod === 'Week' ? 'text-white bg-blue-600' : 'hover:text-white hover:bg-blue-600 hover:shadow-card'}`}
                onClick={handleWeeklyButtonClick}
                >
              Week
            </button>
            <button 
                className={`rounded mx-2 py-1 px-3 text-xs font-medium hover:text-white shadow-card ${selectedTimePeriod === 'Month' ? 'text-white bg-blue-600' : 'hover:text-white hover:bg-blue-600 hover:shadow-card'}`}
                onClick={handleMonthButtonClick}>
              Month
            </button>
          </div>
        </div>
      </div>

      <div>
        <div id="chartOne" className="-ml-5">
          <ReactApexChart
            options={options}
            series={series}
            type="area"
            height={350}
          />
        </div>
      </div>
    </div>
  );
};

export default ChartOne;
