import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux'

import InsightCard from "./InsightCard";

let CardList = () => {

    const insights = useSelector(state => state.user.insights)
    const user = useSelector(state => state.user)

    const [filteredInsights, setFilteredInsights] = useState([]);

    useEffect(() => {
        if (insights && insights.length > 0) {
          let sortAndExtractInsights = () => {
            let insightSort = [...insights]
            try {
                insightSort.sort((a, b) => {
                  const dateA = new Date(a.createdAt);
                  const dateB = new Date(b.createdAt);
                  return dateB - dateA; // For descending order
                });
              } catch (error) {
                console.error('Error during sort:', error);
              }
      
            // Extract all insights
            let allInsights = insightSort.flatMap((item) => item.insights);
            allInsights = allInsights.filter(
              (insight) => insight.insight !== "Error generating insight"
            );
            
            // Sort the insights based on the active flag
            allInsights.sort((a, b) => {
              return b.active - a.active; // For descending order
            });
          
            return allInsights;
          };
          setFilteredInsights(sortAndExtractInsights());
        } else {
          console.log('Insights not set...')
        }
      }, [insights]);

  return (
    <div className="rounded-sm border border-stroke my-2 bg-white px-5 pt-6 pb-2.5 shadow-default sm:px-7.5 xl:pb-1">
      <h4 className="mb-6 text-xl font-semibold">Insight History</h4>
      <InsightCard userName={user.username} insights={filteredInsights} />
    </div>
  );
};

export default CardList;
