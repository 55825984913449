import { React, useState, useEffect } from "react"
import { Routes, Route, Outlet, useLocation, Navigate } from "react-router-dom"
import { shallowEqual, useDispatch } from "react-redux"
import { useCookies } from 'react-cookie'

import NavBar from "./components/NavBar"
import AuthNavBar from "./components/AuthNavBar"
import TestConfig from "./components/TestConfig"
import TestView from "./components/TestView"
import ResultView from "./components/ResultView"
import CognitoRedirect from "./components/CognitoRedirectView"
import DashboardView from "./components/DashboardView"
import InternalView from "./components/InternalView"
import InsightsView from "./components/InsightsView"

import Sidebar from "./components/global/Sidebar"


import { isAuthenticated, isTokenExpired } from "./helper/helper"
import { setUserAuth } from "./redux/user_reducer"

import ReactGA from 'react-ga4'

const App = () => {

  let location = useLocation()
  let dispatch = useDispatch()
  
  let [userSelections, setUserSelections] = useState([])
  let [duration, setDuration] = useState(null)

  const [cookies] = useCookies(['accessToken']);


  useEffect(() => {
    setUserSelections([...userSelections])
  }, userSelections)

  useEffect(() => {
    ReactGA.send('pageview', location.pathname + location.search);
  }, [location]);

  let completeTest = (selectionHistory) => {
    setUserSelections(selectionHistory)
  }

  const PrivateUserRoute = ({ element, ...rest }) => {

    const [isLoading, setIsLoading] = useState(true);
    const [authInfo, setAuthInfo] = useState(false);

    useEffect(() => {
      if (!isLoading && (!authInfo || !authInfo.authStatus) && (!cookies.accessToken || cookies.accessToken === "undefined" || isTokenExpired(cookies.accessToken) === true)) {
        window.location.href = `https://login.cloudcertificationprep.com/oauth2/authorize?client_id=6kk3uee1uflemfg9u31vko4etl&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=${encodeURIComponent(process.env.REACT_APP_CLIENT_HOSTNAME + "/cognito-redirect")}`;
      }
    }, [authInfo, isLoading])
  
    useEffect(() => {
      const checkAuthentication = async () => {
        setAuthInfo(await isAuthenticated(cookies));
        setIsLoading(false);
      }
      checkAuthentication();
    }, []);
  
    if (isLoading) {
      return <div>Loading...</div>
    }

    dispatch(setUserAuth(authInfo))

    return authInfo && authInfo.authStatus === true ? element : null

  };

  const PrivateAdminRoute = ({ element, ...rest }) => {

    const [isLoading, setIsLoading] = useState(true);
    const [authInfo, setAuthInfo] = useState(null);

    useEffect(() => {
      if (!isLoading && (!authInfo || !authInfo.authStatus) && (!cookies.accessToken || cookies.accessToken === "undefined" || isTokenExpired(cookies.accessToken) === true)) {
        window.location.href = `https://login.cloudcertificationprep.com/oauth2/authorize?client_id=6kk3uee1uflemfg9u31vko4etl&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=${encodeURIComponent(process.env.REACT_APP_CLIENT_HOSTNAME + "/cognito-redirect")}`;
      } 
    }, [authInfo, isLoading])
  
    useEffect(() => {
      const checkAuthentication = async () => {
        setAuthInfo(await isAuthenticated(cookies));
        setIsLoading(false);
      }
      checkAuthentication();
    }, []);

    if (isLoading) {
      return <div>Loading...</div>
    }

    dispatch(setUserAuth(authInfo))

    if (!authInfo || !authInfo.role || !authInfo.role.includes('admin')) {
      return <Navigate to="/home" />
    }
  
    return authInfo && authInfo.authStatus === true ? element : null
  }

  return (
    <div className="min-h-full">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="test-config" element={<TestConfig />} />
          <Route path="test" element={<TestView completeTest={completeTest} setDuration={setDuration}/>} />
          <Route path="result" element={<ResultView userSelections={userSelections} duration={duration} />} />
          <Route path="cognito-redirect" element={<CognitoRedirect />} />
          <Route path="*" element={<div>no match</div>} />
        </Route>
        <Route path="home" element={<AuthLayout />}>
          <Route index element={<PrivateUserRoute element={<DashboardView />} />} />
        </Route>
        <Route path="insights" element={<AuthLayout />}>
          <Route index element={<PrivateUserRoute element={<InsightsView />} />} />
        </Route>
        <Route path="internal" element={<AuthLayout />}>
          <Route index element={<PrivateAdminRoute element={<InternalView />} />} />
        </Route>
      </Routes>
    </div>
  )
}

const Layout = () => {
  return (
    <>
      <NavBar />
      <header className="bg-white shadow">
        <div className="mx-auto max-w-7xl py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900">
            Cloud Certification Prep
          </h1>
        </div>
      </header>
      <main>
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
          <Outlet />
        </div>
      </main>
    </>
  );
};

const AuthLayout = () => {
  const [sideMenuIsExpand, setSideMenuIsExpand] = useState(true);

  return (
    <div className="relative min-h-screen md:flex">
      <Sidebar setExpand={setSideMenuIsExpand} />
      <div
        className={`flex-1 min-h-screen mx-0 bg-slate-100 transition-all duration-300 ease-in-out ${
          sideMenuIsExpand ? "md:ml-72" : "md:ml-20"
        }`}
      >
        <AuthNavBar />
        <Outlet />
      </div>
    </div>
  );
};


export default App;
