import { postServerDataWithTokenV2, getServerDataWithTokenV2 } from "../helper/helper.js"

export const pushQuestion = (questionData, token) => async (dispatch) => {
    try {
      if(!questionData) throw new Error("Couldn't get User")
        await postServerDataWithTokenV2(`${process.env.REACT_APP_SERVER_HOSTNAME}/api/questions`, questionData, token, data => data)
    } catch (error) {
        console.log(error)
    }
}

export const pullQuestion = (questionId, token) => async (dispatch) => {
    try {
      if(!questionId) throw new Error("Couldn't get User")
        return await getServerDataWithTokenV2(`${process.env.REACT_APP_SERVER_HOSTNAME}/api/question?questionId=${questionId}`, token, data => data)
    } catch (error) {
        console.log(error)
    }
}

