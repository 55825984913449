import Select from "react-tailwindcss-select"
import { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { useCookies } from 'react-cookie'

import { useSelector, useDispatch } from "react-redux"

import WelcomeModal from "./WelcomeModal"

import { initializeExam } from "../hooks/InitializeExam"
import { fetchTopics } from "../hooks/FetchQuestions"
import { fetchExams } from "../hooks/FetchExams"

let TestConfig = () => {

  const state = useSelector(state => state)

  const [welcomeModal, setWelcomeModal] = useState(true)

  const [examName, setExamName] = useState("")
  const [certificationType, setCertificationType] = useState("AWS Certified Cloud Practitioner")
  const [difficulty, setDifficulty] = useState("easy")
  const [examLength, setExamLength] =  useState(5)
  const [topic, setTopic] = useState(null)
  const [examTypes, setExamTypes] = useState([])
  const [questionType, setQuestionType] = useState("declarative")
  const [options, setOptions] = useState([])

  const [cookies] = useCookies(['accessToken'])

  useEffect(() => {
    fetchTopics(certificationType, questionType).then((data) => {
      const fetchedOptions = data.map((topic, i) => {
        return { value: topic, label: topic }
      })
      setOptions(fetchedOptions)
    })
  }, [certificationType, questionType])

  useEffect(() => {
    const token = cookies.accessToken
    fetchExams(token).then((data) => {
      console.log(data)
      setExamTypes(data)
    })
  },[])

  const dispatch = useDispatch()

  let submitExamDefinition = () => {
    const examResult = {
      examName,
      certificationType,
      difficulty,
      examLength,
      topic,
      questionType
    }
    dispatch(initializeExam(examResult))
  }

  return (
    <>
      {!state.user.auth && (
        <WelcomeModal
          displayModal={welcomeModal}
          setDisplayModal={setWelcomeModal}
        />
      )}
      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Exam Configuration
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                Configure the information in the form to create your exam.
              </p>
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
            <div className="shadow sm:rounded-md">
              <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                <div className="col-span-6 sm:col-span-4">
                  <label
                    htmlFor="exam-name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Name your practice exam
                  </label>
                  <input
                    type="text"
                    name="exam-name"
                    id="exam-name"
                    autoComplete="practice exam"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    onChange={(e) => setExamName(e.target.value)}
                    value={examName}
                  />
                </div>

                <div className="col-span-3 sm:col-span-2">
                  <label
                    htmlFor="certification-type"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Certification Type
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500">
                      {certificationType.split(" ")[0]}
                    </span>
                    <select
                      id="certification type"
                      name="certification"
                      autoComplete="certification-type"
                      onChange={(e) => setCertificationType(e.target.value)}
                      className="block w-full flex-1 rounded-none rounded-r-md border-gray-300 sm:text-sm focus:outline-none"
                      disabled={state.user.auth ? "" : "disabled"}
                    >
                      {examTypes.map((examType, index) => (
                        <option key={index} value={examType}>
                          {examType}
                        </option>
                      ))}{" "}
                    </select>
                  </div>
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="difficulty"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Select the difficulty level
                  </label>
                  <select
                    id="difficulty"
                    name="difficulty"
                    autoComplete="difficulty-level"
                    onChange={(e) => setDifficulty(e.target.value)}
                    className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                    disabled={state.user.auth ? "" : "disabled"}
                  >
                    <option value="easy">Easy</option>
                    <option value="medium">Medium</option>
                    <option value="hard">Hard</option>
                  </select>
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="length"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Select the number of questions for your exam
                  </label>
                  <select
                    id="length"
                    name="length"
                    autoComplete="exam-length"
                    className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                    onChange={(e) => setExamLength(e.target.value)}
                    disabled={state.user.auth ? "" : "disabled"}
                  >
                    <option>5</option>
                    <option>10</option>
                    <option>15</option>
                    <option>20</option>
                    <option>25</option>
                    <option>30</option>
                  </select>
                </div>
                <div className="mt-6 space-y-6">
                  <label
                    htmlFor="question-type"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Select the question type for your exam
                  </label>
                  <div className="grid grid-cols-2 gap-4">
                    <div className="flex items-center">
                      <input
                        id="declarative-question-type"
                        name="question-type"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-blue-500 focus:ring-blue-500"
                        onChange={() => setQuestionType("declarative")}
                        checked={questionType === "declarative"}
                      />
                      <label
                        htmlFor="declarative-question-type"
                        className="block text-sm font-medium leading-6 text-gray-900 ml-2"
                      >
                        Declarative
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="generative-question-type"
                        name="question-type"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-blue-500 focus:ring-blue-500"
                        onChange={() => setQuestionType("generative")}
                      />
                      <label
                        htmlFor="generative-question-type"
                        className="block text-sm font-medium leading-6 text-gray-900 ml-2"
                      >
                        Generative
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="length"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Select the topics for your exam
                  </label>
                  <Select
                    value={topic}
                    onChange={(e) => setTopic(e)}
                    options={options}
                    isMultiple={true}
                  />
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                <button
                  onClick={() => {
                    submitExamDefinition();
                  }}
                  className={`inline-flex justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
                >
                  <Link to={examName && topic ? "/test" : "#"}>
                    Create Pratice Exam
                  </Link>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestConfig;
