import { createSlice } from "@reduxjs/toolkit"

export const questionReducer = createSlice({
    name: "questions",
    initialState: {
        queue: [],
        trace: 0
    },
    reducers: {
        startExamAction: (state, action) => {
            let question = action.payload
            return {
                ...state, 
                queue: question
            }
        },
        moveNextAction: (state) => {
            return{
                ...state,
                trace: state.trace + 1
            }
        },
        movePreviousAction: (state) => {
            return{
                ...state,
                trace: state.trace - 1
            }
        },
        selectQuestionAction: (state, action) => {
            let selection = action.payload
            return {
                ...state,
                trace: selection
            }
        },
        resetAllAction: () => {
            return { 
                queue: [],
                trace: 0
            }
        }
    }
})

export const { startExamAction, moveNextAction, movePreviousAction, selectQuestionAction, resetAllAction } = questionReducer.actions
export default questionReducer.reducer