import * as Action from "../redux/generation_reducer";

import { getServerDataWithTokenV2 } from "../helper/helper.js";

export const generateQuestion = (token) => async (dispatch) => {
  try {
    if (!token) throw new Error("Not authenticated");
    await getServerDataWithTokenV2(
      `${process.env.REACT_APP_SERVER_HOSTNAME}/api/generate`,
      token
    ).then((data) => {
      console.log(data);
      dispatch(
        Action.setGenerations({
          text: JSON.parse(data.citations[0].generatedResponsePart.textResponsePart.text),
          citation: data.citations.flatMap(
            (citation) => citation.retrievedReferences[0]?.content
          ),
        })
      );
    });
  } catch (error) {
    console.log(error);
  }
};

export const generateQuestionFromAgent = (token) => async (dispatch) => {
  try {
    if (!token) throw new Error("Not authenticated");
    await getServerDataWithTokenV2(
      `${process.env.REACT_APP_SERVER_HOSTNAME}/api/generateV2`,
      token
    ).then((data) => {
      console.log(data);
      dispatch(
        Action.setGenerations({
          text: JSON.parse(data.completion),
          citation: data.traces,
        })
      );
    });
  } catch (error) {
    console.log(error);
  }
};
