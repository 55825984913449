import axios from "axios"
import { jwtDecode } from "jwt-decode"
// import qs from "qs"


export const attemptedQuestions = (results) => {
    return results.filter(r => r !== undefined).length
}

export const earnedPoints = (result, answers, point) => {
    return result.map((element, i) => answers[i] === element).filter(i => i).map(i => point).reduce((prev, curr) => prev + curr, 0)
}

export const flagResult = (totalPoints, earnedPoints) => {
    return (earnedPoints/totalPoints) > 0.73
}

export const getServerData = async(url, callback) => {
    const data = await (await axios.get(url))?.data
    return callback ? callback(data) : data
}

export const getServerDataWithToken = async (
  url,
  username,
  token,
  callback
) => {
  const options = {
    headers: {
      authorization: token,
    },
    params: {
      username: username,
    },
  };
  const data = await (await axios.get(url, options))?.data;
  return callback ? callback(data) : data;
};

export const getServerDataWithTokenV2 = async (
  url,
  token,
  callback
) => {
  const options = {
    headers: {
      authorization: token,
    }
  };
  const data = await (await axios.get(url, options))?.data;
  return callback ? callback(data) : data;
};

export const postServerData = async(url, result, callback) => {
    const data = await (await axios.post(url, result))?.data
    return callback ? callback(data) : data
}

export const postServerDataWithToken = async(url, input, token, callback) => {
    const data = await (await axios.post(url, {username: input}, { headers: { authorization: token } }))?.data
    return callback ? callback(data) : data
}

export const postServerDataWithTokenV2 = async(url, input, token, callback) => {
  const data = await (await axios.post(url, input, { headers: { authorization: token } }))?.data
  return callback ? callback(data) : data
}

export const isAuthenticated = async (cookies) => {
  if (!cookies) {
    return false;
  }
  try {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_HOSTNAME}/api/auth`,
      {
        headers: {
          authorization: cookies.accessToken,
        },
      }
    );
    if (response.ok) {
      let role = jwtDecode(cookies.accessToken)['cognito:groups']
      return {
        authStatus: true,
        role: role,
      }
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error checking authentication", error);
    return false;
  }
};

export const isTokenExpired = (token) => {
  if (!token || token === "undefined") {
    return 'Not Set';
  }
  const decodedToken = jwtDecode(token);
  return decodedToken.exp < Date.now() / 1000;
};

